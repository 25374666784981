<template>
  <div>
    <!-- 活动周标题 -->
    <top :islist="islist"></top>
    <!-- 模块标题 -->
    <div class="category">
        <div class="title"><strong><span>科普•</span>讲堂</strong></div>
        <div class="subTitle"><span>Popular Science Lecture Hall</span></div>
      </div>
    <!-- 新闻 -->
   <div class="content-wrapper">
      <h4>{{title}}</h4>
      <p class="time"><span>主讲人：{{author}}</span><span class="line">|</span><span>发布时间：{{time}}</span></p>
      <div class="content" v-html="content"></div>
    </div>
    <page-footer />
  </div>
</template>

<script>
import Top from '@/views/mobile/components/Top.vue';
import PageFooter from './components/PageFooter.vue';
import { getLecture } from '@/api/mobile.js'
import { appTitle } from '@/utils/get-page-title.js'
export default {
  name: 'MLectureDetail',
  components: {
    Top,
    PageFooter
  },
  data() {
    return {
      islist: false,
      content: '',
      title:'',
      author:'',
      time:'',
      id:this.$route.params.id
    };
  },
  created(){
    this.getDetail()
  },
  methods:{
    getDetail(){
      getLecture({id:this.id}).then(res => {
        let {keynoteSpeaker,theme,releaseTime,article} = res.data
        this.title = theme
        document.title = theme + '-' + appTitle
        this.author = keynoteSpeaker
        this.time = releaseTime
        this.content = article
      })
    }
  }
};
</script>

<style scoped lang="scss">
// 分类标题
.category{
  text-align: center;
  position: relative;
}
.category::after{
  display: block;
  content: '';
  width: 460px;
  height: 42px;
  border:1px solid #1046a2;
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.category .title{
  font-size: 36px;
  color: #333;
}
.category .title strong span{
  color:#1046a2;
}
.category .title strong{
  padding:0 10px;
  background-color: #fff;
}
.category .subTitle{
  font-size: 20px;
  color: #333;
}
.category .subTitle span{
  padding:0 10px;
  background-color: #fff;
}
.content-wrapper{
  padding:40px;
}
h4 {
  font-size: 36px;
  color: #333;
  text-align: center;
}
.time {
  font-size: 24px;
  line-height: 24px;
  color: #999;
  text-align: center;
  margin-top:20px;
}
.time .line{
  margin:0px 10px;
}
.content{
  margin-top: 30px;
}
::v-deep .content p{
  line-height: 48px;
  font-size: 30px;
}
::v-deep .content img{
  max-width: 100%;
  height: auto;
}
::v-deep .content iframe,::v-deep .content video{
  max-width: 100%;
  max-height: 400px;
}
</style>
